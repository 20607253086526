import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import './App.css';
import {
  Container, Grid, Typography, Button, TextField, Slider, FormGroup, FormControlLabel, Alert,
  FormLabel, InputLabel, Checkbox, MenuItem, FormControl, Box, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle
}
  from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Header from './Header'
import HowItWorks from './HowItWorks'
import Youtube from './Youtube'
import Costs from './Costs'
import Footer from './Footer'
import Article from './Article'

import { useNavigate } from 'react-router-dom';

function Home() {


  const navigate = useNavigate();

  const [financingType, setFinancingType] = useState('Leasing operacyjny VAT 23');
  const [productionYear, setProductionYear] = useState('2023');
  const [itemPrice, setItemPrice] = React.useState('50000.00');
  const [financingPeriod, setFinancingPeriod] = React.useState<number>(3);
  const [initialValue, setInitialValue] = React.useState<number>(10);
  const [residualValue, setResidualValue] = React.useState<number>(1);
  const [additionalProduct, setAdditionalProduct] = useState(false);

  const [initialNetValue, setInitialNetValue] = React.useState<number>(5000);
  const [residualNetValue, setResidualNetValue] = React.useState<number>(500);

  const [monthlyInstalment, setMonthlyInstalment] = useState(0);

  const [totalLeasingSum, setTotalLeasingSum] = useState(0);
  const [totalLeasingSumPercentage, setTotalLeasingSumPercentage] = useState(0);

  const [validateItemPrice, setValidateItemPrice] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [checkboxError, setCheckboxError] = useState('');


  const effectRan = useRef(false);

  const [isLoaded, setIsLoaded] = useState(false);


  useEffect(() => {

    if (effectRan.current === false) {
      calculate();
    }
    return () => {
      effectRan.current = true;
      setIsLoaded(true);
    }
  }, [financingType, productionYear, itemPrice, additionalProduct]);

  useEffect(() => {
    if (isLoaded === true && parseFloat(itemPrice) >= 20000) {
      calculate();
    }
  }, [itemPrice, financingType, productionYear, additionalProduct]);

  const calculationData = {
    financingType: financingType,
    productionYear: productionYear,
    financingPeriod: financingPeriod,
    itemPrice: itemPrice,
    additionalProduct: additionalProduct,
    initialValue: initialValue,
    residualValue: residualValue,
  };

  const calculate = async () => {
    try {

      const response = await fetch('https://kalkulator.finansowanie.biz/api/operational-lease-calculation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(calculationData),
      });

      if (response.ok) {
        const responseData = await response.json();
        setMonthlyInstalment(responseData.monthlyInstallment);
        setTotalLeasingSumPercentage(responseData.totalPercentageSum);
        setTotalLeasingSum(responseData.totalLeasingSum);
        setInitialNetValue(responseData.initialNetValue);
        setResidualNetValue(responseData.residualNetValue);

      } else {
        console.error('Wysłanie formularza nie powiodło się.');
      }
    } catch (error) {
      console.error('Wystąpił błąd podczas wysyłania formularza:', error);
    }
  };

  const saveData = async () => {
    if (!nip) {
      setError('Wpisz numer NIP');
      return;
    }
    if (!/^\d+$/.test(nip)) {
      setError('Numer NIP może zawierać tylko cyfry');
      return;
    }

    if (nip.length < 10) {
      setError('Numer NIP musi mieć 10 znaków');
      return;
    }
    setError('');

    if (!email) {
      setEmailError('Wpisz adres email');
      return;
    }
    if (!isValidEmail(email)) {
      setEmailError('Nieprawidłowy adres email');
      return;
    }
    setEmailError('');
    if (!phone) {
      setPhoneError('Wpisz numer telefonu');
      return;
    }
    if (!/^\d+$/.test(phone)) {
      setPhoneError('Numer telefonu może zawierać tylko cyfry');
      return;
    }
    if (phone.length < 9) {
      setPhoneError('Numer telefonu musi mieć 9 znaków');
      return;
    }
    setPhoneError('');
    if (!agreement) {
      setCheckboxError('Zaznacz zgodę, aby wysłać formularz');
      return;
    }
    setCheckboxError('');

    try {
      const calculationDataCompleted = {
        calculationData: calculationData,
        nip: nip,
        email: email,
        phone: phone,
        agreement: agreement,
        newsletter: newsletter,
      };
      const response = await fetch('https://kalkulator.finansowanie.biz/api/save-result', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(calculationDataCompleted),
      });

      if (response.ok) {
        setOpen(false);
        navigate('/sukces');
        console.error('OK');

      } else {
        console.error('Wysłanie formularza nie powiodło się.');

      }
    } catch (error) {
      console.error('Wystąpił błąd podczas wysyłania formularza:', error);
    }
  };

  const handleProductionYearChange = (event: SelectChangeEvent) => {
    setProductionYear(event.target.value);
  };

  const handleFinancingTypeChange = (event: SelectChangeEvent) => {
    setFinancingType(event.target.value);
  };

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
  
    // Usuń wszystkie znaki inne niż cyfry, przecinek i kropka
    newValue = newValue.replace(/[^0-9.,]/g, '');
  
    // Zamień przecinek na kropkę
    newValue = newValue.replace(',', '.');
  
    // Sprawdź, czy jest więcej niż jedna kropka
    const dotCount = newValue.split('.').length - 1;
    if (dotCount > 1) {
      // Jeśli jest więcej niż jedna kropka, usuń nadmiarowe kropki
      const parts = newValue.split('.');
      newValue = parts[0] + '.' + parts.slice(1).join('');
    }
  
    // Ogranicz liczbę cyfr po kropce do maksymalnie dwóch
    const parts = newValue.split('.');
    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
      newValue = parts.join('.');
    }
  
    if (parseFloat(newValue) <= 20000) {
      setValidateItemPrice(true);
    } else {
      setValidateItemPrice(false);
    }
  
    setItemPrice(newValue);
  };

  const handleFinancingPeriodChange = (event: Event, newValue: number | number[]) => {
    const newFinancingPeriod = newValue as number;

    if (newFinancingPeriod === 2) {
      setResidualValue(19);
    } else if (newFinancingPeriod > 2) {
      setResidualValue(1);
    }
    setFinancingPeriod(newFinancingPeriod);
  };

  const handleFinancingPeriodChangeCommited = (
    event: React.SyntheticEvent | Event,
    newValue: number | Array<number>) => {
    calculate();

  };

  const handleInitialValueChange = (event: Event, newValue: number | number[]) => {
    setInitialValue(newValue as number);
  };

  const handleInitialValueChangeCommitted = (
    event: React.SyntheticEvent | Event,
    newValue: number | Array<number>) => {
    calculate();
  };


  const handleResidualValueChange = (event: Event, newValue: number | number[]) => {
    const newResidualValue = newValue as number;

    if (financingPeriod !== 2 || newResidualValue >= 19) {
      setResidualValue(newResidualValue);
    }
  };

  const handleResidualValueChangeCommitted = (
    event: React.SyntheticEvent | Event,
    newValue: number | Array<number>) => {
    calculate();
  };

  const updateMaxResidualValue = () => {
    let maxResidualValue = 10;
    if (financingPeriod === 5) {
      maxResidualValue = 30;
    } else if (financingPeriod === 4) {
      maxResidualValue = 35;
    } else if (financingPeriod === 3) {
      maxResidualValue = 40;
    } else if (financingPeriod === 2) {
      maxResidualValue = 40;
    }
    return maxResidualValue;
  };

  const marksFinancialPeriod = [
    { value: 2, label: '2' },

    { value: 5, label: '5' },
  ];

  const marksInitialValue = [
    { value: 0, label: '0' },
    { value: 45, label: '45' },
  ];  

  const marksResidualValue = [
    { value: 1, label: '1' },
    { value: updateMaxResidualValue(), label: `${updateMaxResidualValue()}` },
  ];


  // formularz kontaktowy
  const [nip, setNip] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [agreement, setCheckBox1] = useState(false);
  const [newsletter, setCheckBox2] = useState(false);

  const isValidEmail = (value: string) => {
    // Prosta walidacja adresu email przy użyciu wyrażenia regularnego
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  };

  function valuetext(value: number) {
    return `${value}`;
  }
  
  const formatCurrency = (value: number): string => {
    return value.toLocaleString('pl-PL', {
      style: 'currency',
      currency: 'PLN', // Kod waluty dla polskiego złotego
      minimumFractionDigits: 2,
    });
  };



  
  return (

    <>    
      <Container sx={{ marginBottom: '5%' }}>
        <Header />

        <Grid container spacing={1}>
          <Grid item xs={12} md={8} style={{ textAlign: 'left' }}>
            <div style={{ backgroundColor: '#fff', padding: '16px', borderRadius: '8px' }}>
              <Box sx={{ minWidth: 120 }} marginTop={0} marginBottom={0}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Rodzaj finansowania</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={financingType}
                    onChange={handleFinancingTypeChange}
                    label="Rodzaj finansowania"

                  >
                    <MenuItem value="Leasing operacyjny VAT 23">Leasing operacyjny Faktura VAT 23%</MenuItem>
                    <MenuItem value="Leasing finansowy" disabled>Leasing finansowy</MenuItem>
                    <MenuItem value="Pożyczka leasingowa" disabled>Pożyczka leasingowa</MenuItem>
                    <MenuItem value="Kredyt na samochód" disabled>Kredyt na samochód</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box marginTop={3} marginBottom={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Rok produkcji pojazdu </InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={productionYear}
                        label="Rok produkcji pojazdu"
                        onChange={handleProductionYearChange}
                      >
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2022}>2022</MenuItem>
                        <MenuItem value={2021}>2021</MenuItem>
                        <MenuItem value={2020}>2020</MenuItem>
                        <MenuItem value={2019}>2019</MenuItem>
                        <MenuItem value={2018}>2018</MenuItem>
                        <MenuItem value={2017}>2017</MenuItem>
                        <MenuItem value={2016}>2016</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container spacing={1} >
                    <Grid item xs={7}>
                      <Box marginTop={2} marginBottom={2}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <TextField
                            fullWidth
                            required
                            
                            label='Cena pojazdu netto'
                            variant="outlined"
                            value={itemPrice}
                            onChange={handlePriceChange}
                            size="medium"
                            type="text"
                          />

                        </Box>
                      </Box>
                      {validateItemPrice && (
                        <Alert severity="warning">
                          Kwota musi być wyższa niż 20.000zł
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={5} >
                      <Box marginTop={2} marginBottom={2} >
                      <TextField
  fullWidth
  required
  id="outlined-basic"
  label="brutto"
  variant="outlined"
  value={(parseFloat(itemPrice) * 1.23).toLocaleString('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  })}
  type="text"
  size="medium"
  disabled={true}
/>
                      </Box>
                    </Grid>
                  </Grid>
                  {/* tutaj */}
                  <Grid container sx={{paddingLeft: '3%', paddingRight: '3%'}}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box marginTop={1} marginBottom={1}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <FormLabel component="legend">
                            Okres finansowania w latach
                          </FormLabel>
                          {/* <span>{financingPeriod} {financingPeriod === 2 || financingPeriod === 3 || financingPeriod === 4 ? 'lata' : 'lat'}</span> */}
                        </Box>
                        <Slider
                          aria-label="Financing period"
                          value={financingPeriod}
                          onChange={handleFinancingPeriodChange}
                          onChangeCommitted={handleFinancingPeriodChangeCommited}
                          step={1}
                          marks={marksFinancialPeriod}
                          min={2}
                          max={5}
                          valueLabelDisplay="on"
                          
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Box marginTop={1} marginBottom={1}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <FormLabel component="legend"  style={{ width: '70%' }}>
                            Opłata wstępna netto/brutto
                          </FormLabel>
                          <span style={{ textAlign: 'right', display: 'block' }}>
                          {initialNetValue.toLocaleString('pl-PL', {
  style: 'currency',
  currency: 'PLN',
})} / {(initialNetValue * 1.23).toLocaleString('pl-PL', {
  style: 'currency',
  currency: 'PLN',
})}
</span>

                        </Box>
                        <Slider
                          aria-label="Financing period"
                          value={initialValue}
                          onChange={handleInitialValueChange}
                          onChangeCommitted={handleInitialValueChangeCommitted}
                          step={1}
                          marks={marksInitialValue}
                          min={0}
                          max={45}
                          valueLabelDisplay="on"
                          track="normal"
                          getAriaValueText={valuetext}
                          valueLabelFormat={valuetext}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  {/* tutaj */}
                  
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box marginTop={1} marginBottom={1}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormLabel component="legend" style={{ width: '70%' }}>
  Wykup netto/brutto
</FormLabel>

                          <span style={{ textAlign: 'right', display: 'block' }}>
                          {residualNetValue.toLocaleString('pl-PL', {
  style: 'currency',
  currency: 'PLN',
})} / {(residualNetValue * 1.23).toLocaleString('pl-PL', {
  style: 'currency',
  currency: 'PLN',
})}
</span>

                        </Box>
                        <Slider
                          aria-label="Financing period"
                          value={residualValue}
                          onChange={handleResidualValueChange}
                          onChangeCommitted={handleResidualValueChangeCommitted}
                          step={1}
                          marks={marksResidualValue}
                          min={1}
                          max={updateMaxResidualValue()}
                          valueLabelDisplay="on"
                        />
                      </Box>
                      
                    </Grid>
                    
                  </Grid>
                  </Grid>
                  
                  {/* tutaj */}
                  <Grid container spacing={3}>
                    <Grid item xs={8}>
                      <Box marginTop={0} marginBottom={0}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={additionalProduct} onChange={(event) => setAdditionalProduct(event.target.checked)} />}
                            label="Ubezpieczenie GAP"
                          />
                        </FormGroup>
                      </Box>
                    </Grid>
                  </Grid>
                  {/* tutaj */}
                  
                </Grid>
              </Box>
              <Box sx={{ minWidth: 120 }} >
                <Grid container>
                  <Grid item xs={8}>
                    {/* Pierwsza kolumna, 50% szerokości */}
                  </Grid>
                  <Grid item xs={4}>
                    {/* Druga kolumna, 50% szerokości */}
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>

            <div style={{ backgroundColor: '#1976d2', padding: '16px', borderRadius: '8px', color: 'white' }}>
              {parseFloat(itemPrice) >= 20000 ? (
                <Box borderRadius={2} >
                  <Box style={{ backgroundColor: '#2c88dd' }} paddingTop={1} borderRadius={2} textAlign="center">
                    <Typography variant="body2" display="block" style={{ color: 'white' }}>
                      Suma opłat
                    </Typography>
                    <Typography variant="h3" display="block" gutterBottom>
                      {totalLeasingSumPercentage.toFixed(2)}%
                    </Typography>
                  </Box>
                  <Costs monthlyInstalment={monthlyInstalment} totalLeasingSum={totalLeasingSum} />
                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleClickOpen}
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: 'black',
                        },
                      }}
                    >
                      Zamów ofertę
                    </Button>


                  </Box>
                </Box>
              ) : (
                <Alert severity="warning">Zmień cenę pojazdu na wyższą niż 20.000zł</Alert>
              )}
            </div>
          </Grid>
        </Grid>
        <Youtube />
        <Container sx={{ padding: '2%', backgroundColor: '#A8CBEE', marginTop: '2%', borderRadius: '8px' }}>
          <Typography variant='h6' style={{ marginBottom: '2%', lineHeight: '1', fontFamily: 'Roboto', color: '#000', fontWeight: '100', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
            Dowiedz się jak przebiega proces leasingowy.
          </Typography>
          <HowItWorks />

        </Container>
        <Article />
        <Footer />
      </Container>

      {/* Formularz kontaktowy */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Zamów ofertę</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Proszę pozostawić swoje dane kontaktowe, a skontaktujemy się z Państwem, aby potwierdzić zainteresowanie otrzymaniem oferty oraz omówić szczegóły transakcji.
          </DialogContentText>
          {/* Formularz kontaktowy */}
          <Grid item mt={2}>
            <TextField
              label="Numer NIP"
              value={nip}
              onChange={(e) => setNip(e.target.value)}
              variant="outlined"
              fullWidth
              required
              inputProps={{ maxLength: 10 }}
            />
            {error && <Alert severity="error" sx={{ marginTop: '1%' }}>{error}</Alert>}
          </Grid>
          <Box mt={2}>
            <TextField
              label="Adres email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              fullWidth
              required
            />
            {emailError && <Alert severity="error" sx={{ marginTop: '1%' }}>{emailError}</Alert>}
          </Box>
          <Box mt={2} mb={2}>
            <TextField
              label="Numer telefonu"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 9 }}
              required
            />
            {phoneError && <Alert severity="error" sx={{ marginTop: '1%' }}>{phoneError}</Alert>}
          </Box>
          <Grid mt={2} item xs={12} sx={{ marginTop: 0, marginBottom: 0 }}>
          <FormControlLabel
  control={
    <Checkbox
      checked={agreement}
      onChange={(e) => setCheckBox1(e.target.checked)}
      color="primary"
    />
  }
  label={
    <Typography variant="body2" style={{ fontSize: 12 }}>
      Wyrażam zgodę na przetwarzanie udostępnionych danych w celu otrzymania wyceny i kontaktu w tym przedmiocie. Oświadczam, że akceptuję <a href="https://finansowanie.biz/polityka-prywatnosci/">Politykę Prywatności</a> oraz zapoznałem się z treścią klauzuli informacyjnej w niej zawartej.
    </Typography>
  }
/>
          {checkboxError && <Alert severity="error" sx={{ marginTop: '1%' }}>{checkboxError}</Alert>}
          </Grid>
          <Grid mt={1} item xs={12}>
          <FormControlLabel
  control={
    <Checkbox
      checked={newsletter}
      onChange={(e) => setCheckBox2(e.target.checked)}
      color="primary"
    />
  }
  label={
    <Typography variant="body2" style={{ fontSize: 12 }}>
      Wyrażam zgodę na wykorzystywanie moich danych w postaci adresu e-mail w celu otrzymywania Newslettera.
    </Typography>
  }
/>
          </Grid>
          <DialogActions sx={{ paddingRight: '0', marginRight: '0' }}>
            <Button onClick={handleClose}>Zamknij</Button>
            <Button
              variant="contained"
              onClick={saveData} >
              Wyślij zapytanie
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>


    </>
  );
}
export default Home;
