import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface AlertDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AlertDialog({ isOpen, onClose  }: AlertDialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
<div>
      <Dialog
              fullWidth={true}
              maxWidth="md"
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/NwV2sY2iDDA?si=3BPCTgHMHeJUwCRg"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen={true}
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} autoFocus>
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
