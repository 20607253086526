import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

import Chip from '@mui/material/Chip';

interface CostsProps {
  monthlyInstalment: number;
  totalLeasingSum: number;
}

export default function Costs(props: CostsProps) {
  const [expanded, setExpanded] = React.useState(true);

  const formattedMonthlyInstalmentNetValue = props.monthlyInstalment.toLocaleString('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  });

  const totalMonthlyInstalmentWithVat = (props.monthlyInstalment * 1.23).toLocaleString('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  });

  const formattedTotalLeasingSumNetValue = props.totalLeasingSum.toLocaleString('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  });

  const totalLeasingSumWithVat = (props.totalLeasingSum * 1.23).toLocaleString('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  });

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const captionText = expanded
    ? "Oferta nie stanowi oferty w rozumieniu Kodeksu Cywilnego 71 kodeksu cywilnego.."
    : "Oferta nie stanowi oferty w rozumieniu Kodeksu Cywilnego 71 kodeksu cywilnego. Ogłoszenia, reklamy, cenniki i inne informacje, skierowane do ogółu lub do poszczególnych osób, poczytuje się w razie wątpliwości nie za ofertę, lecz za zaproszenie do zawarcia umowy. Zaproszenie do zawarcia umowy to nie oferta w rozumieniu kodeksu cywilnego, a więc nie ma charakteru wiążącego.";

  const expandText = expanded ? "czytaj dalej" : "zwiń";

  return (
    <div>
      <Typography variant='subtitle1'>Podsumowanie</Typography>

      <TableContainer component={Paper} style={{ height: 'auto', paddingTop: '2%' }}>
        <Table aria-label="a dense table" size="small">
          <caption>
            {captionText}
            <span onClick={handleExpand} style={{ marginLeft: '3px', textDecoration: 'none', color: '#1976D2' }}>
              {expandText}
            </span>
          </caption>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" sx={{ paddingRight: '0%', borderBottom: '0' }}>
                Rata netto
              </TableCell>
              <TableCell align="right" sx={{ paddingLeft: '0%', borderBottom: '0' }}>
                <span style={{ fontWeight: 'bold' }}>
                  <Chip label={formattedMonthlyInstalmentNetValue} color="primary" sx={{ fontFamily: 'Roboto', fontSize: '15px' }} />
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" sx={{ paddingRight: '0%' }}>
                Rata brutto
              </TableCell>

              <TableCell align="right" sx={{ paddingLeft: '0%' }}>
                <span style={{ fontWeight: 'bold' }}>
                  <Chip label={totalMonthlyInstalmentWithVat} color="secondary" variant='outlined' />
                </span>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row" sx={{ paddingRight: '0%', borderBottom: '0' }}>
                Suma opłat netto
              </TableCell>

              <TableCell align="right" sx={{ paddingLeft: '0%', borderBottom: '0' }}>
                <span style={{ fontWeight: 'bold' }}>
                  <Chip label={formattedTotalLeasingSumNetValue} color="primary" sx={{ fontFamily: 'Roboto', fontSize: '15px' }} />
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" sx={{ paddingRight: '0%' }}>
                Suma opłat brutto
              </TableCell>

              <TableCell align="right" sx={{ paddingLeft: '0%' }}>
                <span style={{ fontWeight: 'bold' }}>
                  <Chip label={totalLeasingSumWithVat} color="secondary" variant='outlined' />
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
