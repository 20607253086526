import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

export default function AlignItemsList() {
  return (
    <List sx={{ width: '100%',  bgcolor: 'background.paper', borderRadius: '8px' }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="1" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Przygotuj symulację"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                W szybki sposób oblicz koszt finansowania.
              </Typography>
              {" — Możesz dowolnie manipulować parametrami symulacji i natychmiastowo obserwować efekty w postaci procentowej sumy opłat, wysokości raty leasingowej oraz całkowitej sumy opłat rozłożonej na kwoty netto i brutto."}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="2" src="/static/images/avatar/2.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Wyślij formularz kontaktowy"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Pamiętaj, aby dokładnie wypełnić dane kontaktowe.
              </Typography>
              {" To istotne, ponieważ nasi doradcy skontaktują się z Tobą, aby omówić szczegóły transakcji."}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="3" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Sprawdź maila"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Ofertę wraz z dodatkowymi kosztami otrzymasz drogą mailową.
              </Typography>
              {' — Dodatkowe opłaty są powszechne u wszystkich dostawców leasingu i mogą zawierać wydatki związane z administracją umowy, procesem rejestracji pojazdu oraz ubezpieczeniem od różnicy wartości (ubezpieczenie GAP). Wszelkie te szczegóły znajdziesz w otrzymanym mailu.'}
            </React.Fragment>
          }
        />
      </ListItem>

      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="4" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Złóż wniosek"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Nasz doświadczony doradca poprowadzi Cię przez cały proces.
              </Typography>
              {' — Współpracujemy wyłącznie z ekspertami w dziedzinie leasingu, aby dostarczyć Ci konkretne informacje i wiedzę niezbędną przed podjęciem zobowiązania.'}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
}