import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './Article.css';


type Section = {
  id: string;
  title: string;

};
const sections: Section[] = [
  { id: 'section1', title: '1. Kalkulator leasingu samochodów osobowych i dostawczych - jak korzystać?' },
  { id: 'section2', title: '2. Kalkulator leasingu, a faktyczna oferta.' },
  { id: 'section3', title: '3. Dlaczego warto skorzystać z kalkulatora leasingowego?' },
  { id: 'section4', title: '4. Czy skorzystanie z kalkulatora leasingowego jest darmowe?' },
  { id: 'section5', title: '5. Jakich kosztów dodatkowych nie uwzględnia kalkulator leasingowy?' },
  { id: 'section6', title: '6. Czy kalkulator leasingowy oblicza tylko leasing samochodów osobowych?' },
  { id: 'section7', title: '7. Czy kalkulator rat leasingu oblicza tylko oferty leasingu operacyjnego?' },
  { id: 'section8', title: '8. Znalałem auto w komisie na fakturę VAT marża - czy kalkulator rat leasingowych obliczy koszt finansowania?' },
  { id: 'section9', title: '9. Kalkulator leasingu maszyn i urządzeń - potrzebna oferta?' },
  { id: 'section10', title: '10. Jak szybko otrzymam ofertę leasingową?' },
  { id: 'section11', title: '11. Koszt leasingu, a WIBOR?' },
  { id: 'section12', title: '12. Z jakimi firmami leasingowymi współpracujecie?' },
  { id: 'section13', title: '13. Jakie warunki leasingu muszę spełnić, aby ubiegać się o leasing auta?' },
  { id: 'section14', title: '14. Jak porównywać oferty leasingowe? Czy tylko rata leasingu ma znaczenie?' },

];

const Article: React.FC = () => {
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    section?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Paper sx={{ paddingLeft: '3%', paddingTop: '10px', paddingBottom: '1px', paddingRight: '3%', marginTop: '1%' }}>
      <div id="article">
        <h1>Kalkulator Leasingowy Samochodu - Rata Leasingu Online</h1>

        <p className="articleTheme"><b>Kalkulator Leasingu Samochodu</b> - Rata leasingu w kilka sekund z pomocą kalkulatora leasingowego auta. Oblicz koszt leasingu operacyjnego. Kalkulator leasingowy, to narzędzie, które przygotowaliśmy z myślą o Was, abyście z jego pomocą, w prosty sposób mieli możliwość oszacowania wysokości raty leasingowej. Projektując kalkulator leasingowy, zależało nam na tym, aby jego obsługa była możliwie jak najbardziej uproszczona, a także żeby każdy użytkownik kalkulatora mógł w kilka sekund, (manewrując podstawowymi parametrami leasingowymi), obliczyć potencjalną wysokość miesięcznej raty leasingowej i łącznej sumy opłat leasingowych.
        </p>

        <div className="contents">
          <span className="contentsSpan">Spis treści</span>
          <List>
            {sections.map((section) => (
              <ListItem
                key={section.id}
                button
                onClick={() => scrollToSection(section.id)}
                style={{ padding: '0px' }}
              >
                <ListItemText primary={section.title} />
              </ListItem>
            ))}
          </List>
        </div>
        <div>
          <div>
            {sections.map((section) => (
              <section key={section.id} id={section.id}>
                <h2>{section.title}</h2>
                {section.id === 'section1' && (
                  <div>
                    <p><b>Kalkulator leasingowy</b> w szybki sposób pozwala oszacować koszt raty leasingu samochodu osobowego i dostawczego, a także przedstawia sumę opłat leasingowych wyrażoną kwotowo i procentowo. Aby przeprowadzić wstępną symulację leasingu auta wystarczy, że wybierzesz interesujący Cię produkt finansowy, wybierzesz rok produkcji, wpiszesz cenę pojazdu, a następnie będziesz manewrował podstawowymi parametrami leasingu: opłatą wstępną, kwotą wykupu oraz okresem leasingu.</p>
                    <p>Możesz również zaznaczyć czy jesteś zainteresowany/a ubezpieczeniem GAP. Dokupienie do leasingu ubezpieczenia GAP, w niektórych firmach leasingowych wpływa na koszt finansowania. Kalkulator leasingowy nie oblicza kosztu ubezpieczenia GAP, z uwagi na fakt, iż każdy leasingodawca posiada indywidualne stawki ubezpieczenia, które można oszacować dopiero po uwzględnieniu konretnych informacji od potencjalnego leasingobiorcy.</p>

                    <Paper sx={{ paddingLeft: '15px', paddingTop: '35px', paddingBottom: '35px', paddingRight: '15px', marginTop: '1%', textAlign: 'center' }}>Kalkulator nie przedstawia dodatkowych kosztów, które wpływają na łączny koszt finansowania.<br />
                      <a href="https://youtu.be/kJWrhw7MeJU?si=rsklLRon3kmphkzi" target="_blank">Zobacz film na Youtube o kosztach dodatkowych w leasingu</a></Paper>
                    <p>Aby poznać całkowity koszt leasingu, który uwzględnia dodatkowe opłaty, a także aby otrzymać oficjalną ofertę leasingową, powinieneś/aś uzupełnić formularz kontaktowy, wciskając przycisk "ZAMÓW OFERTĘ". Po uzupełnieniu podstawowych danych kontaktowych, nasz doradca skontaktuje się z Tobą i dopyta o szczegóły, które pomogą mu dopasować ofertę leasingową, która będzie spełniała Twoje wymagania.</p>
                  </div>
                )}
                {section.id === 'section2' && (
                  <div>
                    <p>Pamiętaj, że <b>kalkulator leasingowy</b> pokazuje szacunkowe koszty. Zależy nam na tym, aby rata leasingowa i suma opłat leasingowych, którą zobaczysz na ekranie była na poziomie zbliżonym do tego, który obliczy dla Ciebie kalkulator.</p>
                    <p>Aby otrzymać precyzyjną ofertę leasingową na samochód, powinieneś uzupełnić formularz kontaktowy, abyśmy mogli przygotować ofertę finansowania z uwzględnieniem kosztów dodatkowych.</p>
                  </div>
                )}



                {section.id === 'section3' && (
                  <div>
                    <p>Jeżeli znalazłeś/aś, auto które planujesz sfinansować leasingiem, to zapewne sprzedający pojazd, zaproponuje Ci kontakt z doradcą leasingowym, z którym współpracuje, który przygotuje dla Ciebie symulację finansowania. Kalkulator leasingowy pozwoli Ci w szybki sposób zweryfikować czy oferta finansowania, którą otrzymałeś jest korzystna czy też nie.</p>
                    <p>
                      Często bywa tak, że oferty przedstawione przez doradców leasingowych, którzy współpracują ze sprzedającymi pojazdy są drogie, dlatego kalkulator leasingowy pozwoli Ci w szybki sposób sprawdzić czy oferta, którą otrzymałeś/aś jest akceptowalna, czy też warto szukać korzystniejszych opcji.
                    </p>
                    <p>Jeżeli rata leasingowa, którą obliczysz, korzystając z kalkulatora leasingowego będzie dla Ciebie korzystna, wówczas możesz wysłać formularz kontaktowy, a my skontaktujemy się z Tobą i przygotujemy dopasowaną ofertę leasingową.
                    </p>
                  </div>
                )}

                {section.id === 'section4' && (
                  <div>
                    <p>
                      Oczywiście. Kalkulator leasingowy jest i będzie darmowy. Narzędzie powstało po to, aby zapewnić Państwu szybkie oszacowanie kosztów finansowania, a także aby skontaktować Państwa z doświadczonymi doradcami leasingowymi.</p>
                    <p>Warto wspomnieć, że w sytuacji, w której zdecydujesz się skorzystać z oferty przedstawionej przez partnerów współpracujących z naszą firmą, jest fakt że doradcy z którymi współpracujemy to wyłącznie doświadczone osoby z bogatym doświadczeniem i wiedzą, w zakresie finansowania przedsiębiorstw.</p>
                  </div>
                )}

                {section.id === 'section5' && (
                  <div>
                    <p>Pamiętaj, że w przypadku leasingu samochodu osobowego lub dostawczego, rata leasingowa nie będzie jedynym kosztem, z którym należy się liczyć. Kalkulator leasingu nie wskazuje kosztów takich jak:</p>
                    <ul>
                      <li>Opłata związana z administrowaniem polisą zewnętrzną - większość firm leasingowych pobiera opłaty za chęć skorzystania z obcego (zewnętrznego) ubezpieczenia komunikacyjnego. Zazwyczaj jest to opłata pobierana każdego roku, jeżeli zawrzesz polisę komunikacyjną we własnym zakresie.</li>
                      <li>Opłata związana z ubezpieczeniem GAP - jest to również opłata, którą powinieneś wziąć pod uwagę, jeżeli decydujesz się skorzystać z leasingu na samochód.</li>
                      <li>Opłaty administracyjne i inne - każdy leasingodawca posiada swoje opłaty, które często nie są wyszczególnione w ofertach leasingowych, a są widoczne w tabelach opłat i prowizji. Trzeba na nie również zwrócić szczególną uwagę, licząc łączny koszt finansowania.</li>
                    </ul>
                  </div>
                )}

                {section.id === 'section6' && (
                  <div>
                    <p>Kalkulator został przygotowany z myślą o leasingu samochodów osobowych, natomiast nic nie stoi na przeszkodzie, aby wykorzystująć kalkulator leasingowy, obliczyć koszt leasingu  samochodu ciężarowego, ciągnika siodłowego lub innego pojazdu. Kalkulacja leasingu przeprowadzona za pomocą kalkulatora pozwoli Ci oszacować wysokość raty w przybliżeniu. Jeżeli jednak będziesz zainteresowany/a poznaniem konkretnej oferty auta innego niż pojazd osobowy lub dostawczy, to wystraczy, że skontaktujesz się z nami, a przygotujemy precyzyjną symulację dowolnego przedmiotu leasingu.</p>
                  </div>
                )}

                {section.id === 'section7' && (
                  <div>
                    <p>Aktualnie obliczamy wyłącznie koszt leasingu operacyjnego samochodów. W przyszłości planujemy rozbudowę kalkulatora leasingowego o leasing finansowy, pożyczkę leasingową, a także kredyt samochodowy.</p>
                  </div>
                )}

                {section.id === 'section8' && (
                  <div>
                    <p>W przypadku leasingu samochodu sprzedawanego na fakturę VAT marża sugeruje się skorzystanie z innych produktów finansowych niż leasing operacyjny. Wynika to z faktu, iż na fakturze VAT marża nie występuje podatek VAT, a do leasingu operacyjnego VAT jest doliczany. W związku z powyższym korzystne w takim wypadku jest wybranie produktu finansowego, który nie doliczy podatku VAT do leasingu. Takimi produktami są przykładowo leasing finansowy lub pożyczka leasingowa. </p>
                  </div>
                )}


                {section.id === 'section9' && (
                  <div>
                    <p>Specjalizujemy się również z leasingu maszyn i urządzeń - nowych i używanych. Musisz wiedzieć, że zazwyczaj koszt finansowania maszyn jest wyższy od leasingu samochodowego, dlatego jeżeli potrzebujesz konkretnej symulacji wraz z dodatkowymi kosztami, to koniecznie uzupełnij formularz kontaktowy albo skontaktuj się z nami mailowo lub telefonicznie. Dane kontaktowe znajdziesz na samym dole strony w sekcji kontakt.</p>
                  </div>
                )}

                {section.id === 'section10' && (
                  <div>
                    <p>Od momentu wysłania formularza kontaktowego staramy się, aby skontaktować się z Państwem w możliwie najkrótszym czasie, po to aby doprecyzować Państwa wymagania i wybrać właściwego leasingodawcę. Ważne natomiast jest to, że staramy się aby każda ofertą, którą przygotowujemy była co najmniej na takim poziomie cenowym, jak rata wskazana przez kalkulator leasingowy. </p>
                  </div>
                )}

                {section.id === 'section11' && (
                  <div>
                    <p>Kalkulator leasingowy oblicza ratę leasingu operacyjnego w oparciu o zmienny WIBOR 1M. Istnieje możliwość otrzymania symulacji na stałej stopie procentowej, natomiast wymaga to kontaktu z naszą firmą i wyrażeniu chęci otrzymania takiego wariantu.</p>
                  </div>
                )}

                {section.id === 'section12' && (
                  <div>
                    <p>W kalkulatorze leasingowym uwzględniliśmy ratę leasingową bazując na przyjętej przez nas marży i stawce WIBOR 1M. To jakiego leasingodawcę dla Ciebie wybierzemy jest uzależnione od kilku czynników, takich jak:</p>
                    <ul>
                      <li>Rok produkcji pojazdu,</li>
                      <li>Preferowana wartość wykupu pojazdu na koniec okresu leasingu,</li>
                      <li>Okres finansowania,</li>
                      <li>Wysokość opłaty wstępnej.</li>
                    </ul>
                    <p>Każdy leasingodawca może mieć inne wymagania wobec klienta jak i przedmiotu, który finansuje, natomiast chcemy zaznaczyć, iż współpracujemy wyłącznie z czołowymi firmami leasingowymi w Polsce. Unikamy firm, które na polskim rynku są krótko lub mają złą opinię wśród klientów.</p>
                  </div>
                )}

                {section.id === 'section13' && (
                  <div>
                    <p>Przy leasingu samochodu ważne jest aby prowadzić działalność gospodarczą. Niektóre firmy leasingowe umożliwiają wnioskowanie o leasing nawet nowym firmom, przy zapewnieniu odpowiedniej wysokości opłaty wstępnej oraz po spełnieniu dodatkowych kryteriów, które w wielu przypadkach dotyczą potwierdzenia doświadczenia zawodowego lub przedstawienia majątku osobistego. </p>

                    <p>Jeżeli dopiero rozpocząłeś działalność gospodarczą, to koniecznie obejrzyj film, w którym opowiadam jak ubiegać się o leasing, kiedy jesteś nową firmą - inaczej mówiąc start-upem. </p>

                    <Paper sx={{ paddingLeft: '15px', paddingTop: '35px', paddingBottom: '35px', paddingRight: '15px', marginTop: '1%', textAlign: 'center' }}>Leasing dla start-up'ów.<br />
                      <a href="https://youtu.be/kFTAa4LXzyg?si=82qNZuweA7Ee80u2" target="_blank">Zobacz film na Youtube o leasingu dla nowych firm</a></Paper>
                  </div>
                )}

                {section.id === 'section14' && (
                  <div>
                    <p>Jest to ważna kwestia, ponieważ zazwyczaj doradcy leasingowi wysyłają klientom wyłącznie informację o wysokości rat leasingowych i nie wskazują kosztów dodatkowych, które w wielu przypadkach determinują czy oferta leasingowa jest korzystna czy też nie. </p>

                    <p>Kalkulator leasingowi stanowi idealne rozwiąznaie dla osób, które chcą porównać sam koszt finansowania, natomiast później należy wziąć pod uwagę koszty dodatkowe.</p>

                      <br/>
                  </div>
                  
                )}
              </section>
            ))}
          </div>
        </div>


      </div>




    </Paper>
  );
};

export default Article;
