// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#article {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.43;
}
.contents {
    background-color: #A8CBEE;
    border-radius: 5px;
    padding: 2%;
    width: 80%;
}

#article h1 {
    font-size: 28px;
    font-weight: 400;
}
#article h2 {
    font-size: 22px;
    font-weight: 700;
    margin-top: 25px;
}
.articleTheme {
    font-size: 18px !important;
    font-style: italic;
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.6;
}
#article p {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.6);
    line-height: 2;
}
.contentsSpan{
    text-decoration: underline;
}
#article li {
    font-size: 18px !important;
    color: rgba(0, 0, 0, 0.6);
    line-height: 2;
}`, "",{"version":3,"sources":["webpack://./src/components/Article.css"],"names":[],"mappings":"AAAA;IACI,oDAAoD;IACpD,0BAA0B;IAC1B,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,cAAc;AAClB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":["#article {\r\n    font-family: \"Roboto\",\"Helvetica\",\"Arial\",sans-serif;\r\n    color: rgba(0, 0, 0, 0.87);\r\n    line-height: 1.43;\r\n}\r\n.contents {\r\n    background-color: #A8CBEE;\r\n    border-radius: 5px;\r\n    padding: 2%;\r\n    width: 80%;\r\n}\r\n\r\n#article h1 {\r\n    font-size: 28px;\r\n    font-weight: 400;\r\n}\r\n#article h2 {\r\n    font-size: 22px;\r\n    font-weight: 700;\r\n    margin-top: 25px;\r\n}\r\n.articleTheme {\r\n    font-size: 18px !important;\r\n    font-style: italic;\r\n    color: rgba(0, 0, 0, 0.6);\r\n    line-height: 1.6;\r\n}\r\n#article p {\r\n    font-size: 16px !important;\r\n    color: rgba(0, 0, 0, 0.6);\r\n    line-height: 2;\r\n}\r\n.contentsSpan{\r\n    text-decoration: underline;\r\n}\r\n#article li {\r\n    font-size: 18px !important;\r\n    color: rgba(0, 0, 0, 0.6);\r\n    line-height: 2;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
