// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/bgbody.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-color: #DAEDFF;
  background-size: cover;
  background-repeat: no-repeat;
}
.checkboxLabel {
  font-size: 10px !important;
}
.MuiSlider-valueLabel  {

  background-color: transparent !important;
  

}
.MuiSlider-thumbSizeSmall {
  size: none !important;
  background-color: transparent !important;

}
.MuiSlider-thumbColorPrimary {

width:  44px !important;
height: 24px !important;
border-radius: 5px !important;

}
.MuiSlider-valueLabelOpen {
  transform: none !important;
  top: initial !important;
  
}
.MuiSlider-valueLabel {
  color: white !important;
  font-weight: 700 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/App.css"],"names":[],"mappings":"AAAA;EACE,yDAAgD;EAChD,yBAAyB;EACzB,sBAAsB;EACtB,4BAA4B;AAC9B;AACA;EACE,0BAA0B;AAC5B;AACA;;EAEE,wCAAwC;;;AAG1C;AACA;EACE,qBAAqB;EACrB,wCAAwC;;AAE1C;AACA;;AAEA,uBAAuB;AACvB,uBAAuB;AACvB,6BAA6B;;AAE7B;AACA;EACE,0BAA0B;EAC1B,uBAAuB;;AAEzB;AACA;EACE,uBAAuB;EACvB,2BAA2B;AAC7B","sourcesContent":["body {\r\n  background-image: url('../../public/bgbody.svg');\r\n  background-color: #DAEDFF;\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n}\r\n.checkboxLabel {\r\n  font-size: 10px !important;\r\n}\r\n.MuiSlider-valueLabel  {\r\n\r\n  background-color: transparent !important;\r\n  \r\n\r\n}\r\n.MuiSlider-thumbSizeSmall {\r\n  size: none !important;\r\n  background-color: transparent !important;\r\n\r\n}\r\n.MuiSlider-thumbColorPrimary {\r\n\r\nwidth:  44px !important;\r\nheight: 24px !important;\r\nborder-radius: 5px !important;\r\n\r\n}\r\n.MuiSlider-valueLabelOpen {\r\n  transform: none !important;\r\n  top: initial !important;\r\n  \r\n}\r\n.MuiSlider-valueLabel {\r\n  color: white !important;\r\n  font-weight: 700 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
