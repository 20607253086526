import React, { useState } from 'react';
import './Header.css';
import {Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ShowMovie from './ShowMovie';


const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      <div className="kontener">
        <Grid container>
          <Grid item>
            <Typography variant='h6' style={{ marginBottom: '2%', lineHeight: '1', fontFamily: 'Roboto', color: '#000', fontWeight: '100', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
              Finansowanie Biznesu
            </Typography>
            <Typography variant='h2' style={{ lineHeight: '1', fontFamily: 'Roboto', color: '#0066cc', fontWeight: '400', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
              Obliczamy raty<br />
              leasingowe
            </Typography>

            <Typography variant='h5' style={{ fontFamily: 'Roboto', fontWeight: '200', color: 'black', marginBottom: '2%', marginTop: '4%', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
              Oblicz ratę leasingową na Twój<br />
              wymarzony pojazd w kilka sekund.
            </Typography>
            <Button variant='contained' size='large' sx={{ marginTop: '2%' }} onClick={() => setIsModalOpen(true)}>
    Obejrzyj nagranie
            </Button>
            {isModalOpen && (
        <ShowMovie
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}

        />
      )}
          </Grid>
        </Grid>
      </div>
      </>
  );
}

export default Header;