import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";

export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 2, // Zmniejszamy wartość 'p' dla mniejszego wewnętrznego paddingu
        mt: 4, // Margines górny
        borderRadius: 2,
      }}
      component="footer"
    >
      <Container maxWidth="md" >
        <Typography variant="body2" color="text.secondary" align="center" gutterBottom>
          © {new Date().getFullYear()} All rights reserved.
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          <strong>Kontakt:</strong>
          <br />
          E-mail: <Link href="mailto:info@finansowanie.biz">info@finansowanie.biz</Link>
          <br />
          Telefon: <Link href="tel:+48533533742">+48 533-533-742</Link>
        </Typography>
      </Container>
    </Box>
  );
}
