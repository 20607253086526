import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  Container, Grid
} from '@mui/material';
export default function Youtube() {
  return (
    <>
    <Container sx={{paddingTop: '3%', paddingBottom: '3%'}}>


    <Grid container spacing={0} style={{ paddingTop: '16px', paddingBottom: '16px', alignItems: 'center', justifyContent: 'center' }}>
            <Grid item md={6}>
        <Typography variant='h6' style={{ marginBottom: '2%', lineHeight: '1', fontFamily: 'Roboto', color: '#000', fontWeight: '100', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
          Materiały powiązane z leasingiem
        </Typography>
      </Grid>
      <Grid item md={6} style={{ textAlign: 'right' }}>
        <Button variant='outlined' href="https://www.youtube.com/@AndrzejCzarny" target="_blank" rel="noopener noreferrer">Zobacz wszystkie filmy</Button>
      </Grid>
    </Grid>
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      
      <Grid item md={4}>
      <Card sx={{ padding: 0 , margin: 0}}>
      <CardMedia
        sx={{ height: 140 }}
        image="/thumb2.png"
        title="kalkulator leasingowy"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Kalkulator leasingowy
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Dowiedz się jak w szybki sposób możesz obliczyć wysokość rat leasingowych oraz sumę opłat leasingowych.
        </Typography>
      </CardContent>
      <CardActions>
      <Button size="small" component="a" href="https://youtu.be/NwV2sY2iDDA?si=BfVSWA3bJVBi4spG" target="_blank" rel="noopener noreferrer">
      Zobacz na Youtube
    </Button>
      </CardActions>
    </Card>
      </Grid>

      <Grid item md={4}>
      <Card sx={{  padding: 0 , margin: 0}}>
      <CardMedia
        sx={{ height: 140 }}
        image="/thumb.png"
        title="Oszczędność na VAT"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Jak oszczędzić na VAT
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Ciekawy sposób na znalezienie oszczędności na podatku VAT w przypadku finansowania samochodu osobowego. 
        </Typography>
      </CardContent>
      <CardActions>
      <Button size="small" component="a" href="https://youtu.be/SuYGdI-RFew?si=G_YGkLDHkKOYSg9a" target="_blank" rel="noopener noreferrer">
      Zobacz na Youtube
    </Button>
      </CardActions>
    </Card>
      </Grid>

      <Grid item md={4}>
      <Card  sx={{  padding: 0 , margin: 0}}>
      <CardMedia
        sx={{ height: 140 }}
        image="/thumb3.png"
        title="opłaty w leasingu"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Opłaty w leasingu
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Poznaj opłaty, które mogą się wystąpić przy umowie leasingowej. Dowiedz się jakich opłat możesz się spodziewać.
        </Typography>
      </CardContent>
      <CardActions>
      <Button size="small" component="a" href="https://youtu.be/kJWrhw7MeJU?si=nAUD2656bzySjVvp" target="_blank" rel="noopener noreferrer">
      Zobacz na Youtube
    </Button>
      </CardActions>
    </Card>
      </Grid>
      </Grid>    </Container>



      </>

    
  );
}