import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Success() {
  const navigate = useNavigate();

  const handleBackToCalculator = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
      }}
      component="div"
    >
      {/* Wstaw tutaj swój kod <img> */}
      <img src="/../success.png" alt="Sukces" style={{ marginBottom: '16px', maxWidth: '75%', height:'auto' }} />


      <Typography variant="h5">
        Dziękujemy za wysłanie formularza
      </Typography>
      
      <Typography>
        Skontaktujemy się z Tobą tak szybko, jak to będzie możliwe.
      </Typography>
      
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '12px' }}
        onClick={handleBackToCalculator}
      >
        Wróć do kalkulatora
      </Button>
    </Box>
  );
}
