// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    width: 100%;
    background-color: #a6d6f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .header-content {
    width: calc(100% - 75%);
    text-align: center;
    padding: 16px;
  }




  .kontener {
    position: relative;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 45% auto; /* Domyślna szerokość dla dużego okna */
    background-repeat: no-repeat;
    background-position: right;
    height: 350px;
    margin-bottom: 1%;
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    /* Dla mniejszych okien */
    .kontener {
      background-size: 90% auto; /* Dopasowuje się do szerokości kontenera */
      background-position: center;
      background-image: none;
    }
    h2 {
      font-size: 40px !important;
      font-weight: bold !important; 
    }
    h5 {
      font-size: 22px !important;
    }

  }
  
  .image {
    height: 300px;
    max-width: 100%;
    border-radius: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;EACf;;;;;EAKA;IACE,kBAAkB;IAClB,yDAAuC;IACvC,yBAAyB,EAAE,uCAAuC;IAClE,4BAA4B;IAC5B,0BAA0B;IAC1B,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB;MACE,yBAAyB,EAAE,2CAA2C;MACtE,2BAA2B;MAC3B,sBAAsB;IACxB;IACA;MACE,0BAA0B;MAC1B,4BAA4B;IAC9B;IACA;MACE,0BAA0B;IAC5B;;EAEF;;EAEA;IACE,aAAa;IACb,eAAe;IACf,mBAAmB;EACrB","sourcesContent":[".header-container {\r\n    width: 100%;\r\n    background-color: #a6d6f4;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n  }\r\n  \r\n  .header-content {\r\n    width: calc(100% - 75%);\r\n    text-align: center;\r\n    padding: 16px;\r\n  }\r\n\r\n\r\n\r\n\r\n  .kontener {\r\n    position: relative;\r\n    background-image: url('/public/bg.png');\r\n    background-size: 45% auto; /* Domyślna szerokość dla dużego okna */\r\n    background-repeat: no-repeat;\r\n    background-position: right;\r\n    height: 350px;\r\n    margin-bottom: 1%;\r\n    border-radius: 10px;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    align-items: center;\r\n  }\r\n  \r\n  @media (max-width: 768px) {\r\n    /* Dla mniejszych okien */\r\n    .kontener {\r\n      background-size: 90% auto; /* Dopasowuje się do szerokości kontenera */\r\n      background-position: center;\r\n      background-image: none;\r\n    }\r\n    h2 {\r\n      font-size: 40px !important;\r\n      font-weight: bold !important; \r\n    }\r\n    h5 {\r\n      font-size: 22px !important;\r\n    }\r\n\r\n  }\r\n  \r\n  .image {\r\n    height: 300px;\r\n    max-width: 100%;\r\n    border-radius: 10px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
